import { render, staticRenderFns } from "./routerPanel.vue?vue&type=template&id=c3270898&scoped=true&"
import script from "./routerPanel.vue?vue&type=script&lang=js&"
export * from "./routerPanel.vue?vue&type=script&lang=js&"
import style0 from "./routerPanel.vue?vue&type=style&index=0&id=c3270898&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3270898",
  null
  
)

export default component.exports