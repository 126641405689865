<template>
  <div class="realEstatePanel">
    <div class="mt-2">
      <!-- Header: New Router -->
      <div class="d-flex">
        <feather-icon icon="SendIcon" size="19"/>
        <h4 class="mb-0 ml-50">
          {{ msg('New Router') }}
        </h4>
      </div>

      <!-- Form: Router Info -->
      <b-row class="mt-1">

        <b-col cols="12">
          <b-row>
            <b-col cols="4" align-self="center">
              <b-img :src="router.imageUri" fluid thumbnail style="max-width: 240px; max-height: 300px"/>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <b-form-group :label="msg('Model')">
                    <b-input v-model="router.model"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :label="msg('Operator')">
                    <b-input v-model="router.network"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <!-- WiFi Name -->
        <b-col cols="6">
          <b-form-group :label="msg('WiFi Name')">
            <b-form-input v-model="router.wifiName"/>
          </b-form-group>
        </b-col>

        <!-- WiFi Password -->
        <b-col cols="6">
          <b-form-group :label="msg('WiFi Password')">
            <b-form-input v-model="router.wifiPassword"/>
          </b-form-group>
        </b-col>

        <!-- Phone Number -->
        <b-col cols="6">
          <b-form-group :label="msg('Phone Number')">
            <b-form-input v-model="router.phone" type="tel"/>
          </b-form-group>
        </b-col>

        <!-- PIN -->
        <b-col cols="6">
          <b-form-group :label="msg('PIN')">
            <b-form-input v-model="router.pin" type="number"/>
          </b-form-group>
        </b-col>

        <!-- PUK -->
        <b-col cols="6">
          <b-form-group :label="msg('PUK')">
            <b-form-input v-model="router.puk" type="number"/>
          </b-form-group>
        </b-col>

        <!-- Simcard Number -->
        <b-col cols="6">
          <b-form-group :label="msg('Simcard Number')">
            <b-form-input v-model="router.simcardNumber" type="number"/>
          </b-form-group>
        </b-col>

        <!-- Router Password -->
        <b-col cols="6">
          <b-form-group :label="msg('Router Password')">
            <b-form-input v-model="router.routerPassword"/>
          </b-form-group>
        </b-col>

        <!-- Last Charge -->
        <b-col cols="6">
          <b-form-group :label="msg('Last Charge')">
            <b-form-datepicker v-model="router.lastCharge" />
          </b-form-group>
        </b-col>


        <!-- Contract Type -->
        <b-col cols="6">
          <b-form-group :label="msg('Contract Type')">
            <b-form-select
                v-model="router.contractType"
                :options="[
                { value: 'simRouterNoContract', text: msg('Sim Router (No Contract)') },
                { value: 'simRouterWithContract', text: msg('Sim Router (With Contract)') },
                { value: 'fiberInternet', text: msg('Fiber Internet (With Contract)') }
              ]"
            />
          </b-form-group>
        </b-col>

        <!-- Status -->
        <b-col cols="6">
          <b-form-group :label="msg('Status')">
            <b-form-select
                v-model="router.status"
                :options="[
                { value: 'online', text: msg('Online') },
                { value: 'offline', text: msg('Offline') },
                { value: 'inProgress', text: msg('In Progress') },
                { value: 'error', text: msg('Error') }
              ]"
            />
          </b-form-group>
        </b-col>

        <!-- Login Link -->
        <b-col cols="6">
          <b-form-group :label="msg('Login Link')">
            <b-form-input v-model="router.loginLink"/>
          </b-form-group>
        </b-col>


        <!-- Branch ID -->
        <b-col cols="6">
          <b-form-group :label="msg('Branch')">
            <v-select
                v-model="router.buid"
                :options="allBranches"
                class="w-100"
                label="name"
                :reduce="(branch) => branch.id"
                :clearable="false"

            />
          </b-form-group>
        </b-col>
    <!-- Branch ID -->
        <b-col cols="12">
          <b-form-group :label="msg('Image Uri')">
            <b-input v-model="router.imageUri"/>
          </b-form-group>
        </b-col>


        <b-col cols="12">
          <b-form-group :label="msg('Real estate')">
            <real-estate-picker v-model="router.realEstateId"/>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Save Button -->
      <div v-if="router.buid!=null" style="float: right" class="justify-content-center mt-2">
        <b-button variant="outline-primary" class="btn-dialog justify-content-center" @click="createRouter">
          {{ msg('Save') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import {msg} from "@babel/core/lib/config/validation/option-assertions";

export default {
  name: 'routerPanel',
  components: {RealEstatePicker},
  data() {
    return {
      loaded: false,
      contractId: null,
      router: {},
      clientId: null,
      contractList: [],
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },


  computed: {
    ...mapGetters('data', ['getAllBranches']),


    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {
    let $this = this;

    this.$root.$on('crm::submitRouter', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitRouter', this.listeners.submit)
  },
  methods: {
    msg,
    ...mapActions('router', ['getAllRouters', 'copySuccess', 'addRouter']),
    ...mapActions('realEstateContracts', ['getAllByClients']),

    formatDate(date) {
      return date ? this.$moment(date, "YYYY-MM-DD").format("DD.MM.YYYY") : "No date";
    },

    createRouter() {
      let $this = this

      this.addRouter({router:this.router})
          .then(
              (id) => {

                $this.$emit('submitted', {status: true, server: id, id})

              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },

    validate(field) {
      return this.$v.router[field] != null && this.$v.router[field].$error === true
    }
    ,
    submit() {
      return this.createRouter()
    },

  },

}
</script>
<style scoped>

.dark-layout #realEstate-description {
  background-color: rgba(255, 255, 255, 0.66);
  color: #000000;
}

.quill-container #realEstate-description {

  min-height: 400px;
}
</style>


