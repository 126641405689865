<template>
  <div class="realEstatePanel">
    <div class="mt-2">
      <!-- Header: New Router -->
      <div class="d-flex">
        <i class="fas fa-wifi" style="margin-right: 2px"></i>
        <h4 class="mb-0 ml-50">
          {{ msg('Update Router') }}
        </h4>
      </div>

      <!-- Form: Router Info -->
      <b-row class="mt-1">
        <b-col cols="12">
          <b-row>
            <b-col cols="4" align-self="center">
              <b-img :src="router.imageUri" fluid thumbnail style="max-width: 240px; max-height: 300px"/>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <b-form-group :label="msg('Model')">
                    <b-input v-model="router.model"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :label="msg('Operator')">
                    <b-input v-model="router.network"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <!-- WiFi Name -->
        <b-col cols="6">
          <b-form-group :label="msg('WiFi Name')">
            <b-form-input v-model="router.wifiName"/>
          </b-form-group>
        </b-col>

        <!-- WiFi Password -->
        <b-col cols="6">
          <b-form-group :label="msg('WiFi Password')">
            <b-form-input v-model="router.wifiPassword"/>
          </b-form-group>
        </b-col>

        <!-- Phone Number -->
        <b-col cols="6">
          <b-form-group :label="msg('Phone Number')">
            <b-form-input v-model="router.phone" type="tel"/>
          </b-form-group>
        </b-col>

        <!-- PIN -->
        <b-col cols="6">
          <b-form-group :label="msg('PIN')">
            <b-form-input v-model="router.pin" type="number"/>
          </b-form-group>
        </b-col>

        <!-- PUK -->
        <b-col cols="6">
          <b-form-group :label="msg('PUK')">
            <b-form-input v-model="router.puk" type="number"/>
          </b-form-group>
        </b-col>

        <!-- Simcard Number -->
        <b-col cols="6">
          <b-form-group :label="msg('Simcard Number')">
            <b-form-input v-model="router.simcardNumber" type="number"/>
          </b-form-group>
        </b-col>

        <!-- Router Password -->
        <b-col cols="6">
          <b-form-group :label="msg('Router Password')">
            <b-form-input v-model="router.routerPassword"/>
          </b-form-group>
        </b-col>

        <!-- Last Charge -->
        <b-col cols="6">
          <b-form-group :label="msg('Last Charge')">
            <b-form-datepicker v-model="router.lastCharge"/>
          </b-form-group>
        </b-col>

        <!-- Contract Type -->
        <b-col cols="6">
          <b-form-group :label="msg('Contract Type')">
            <b-form-select
                v-model="router.contractType"
                :options="[
                { value: 'simRouterNoContract', text: msg('Sim Router (No Contract)') },
                { value: 'simRouterWithContract', text: msg('Sim Router (With Contract)') },
                { value: 'fiberInternet', text: msg('Fiber Internet (With Contract)') }
              ]"
            />
          </b-form-group>
        </b-col>

        <!-- Status -->
        <b-col cols="6">
          <b-form-group :label="msg('Status')">
            <b-form-select
                v-model="router.status"
                :options="[
                { value: 'online', text: msg('Online') },
                { value: 'offline', text: msg('Offline') },
                { value: 'inProgress', text: msg('In Progress') },
                { value: 'error', text: msg('Error') }
              ]"
            />
          </b-form-group>
        </b-col>

        <!-- Login Link -->
        <b-col cols="6">
          <b-form-group :label="msg('Login Link')">
            <b-form-input v-model="router.loginLink"/>
          </b-form-group>
        </b-col>


        <!-- Branch ID -->
        <b-col cols="6">
          <b-form-group :label="msg('Branch')">
            <v-select
                v-model="router.buid"
                :options="allBranches"
                class="w-100"
                label="name"
                :reduce="(branch) => branch.id"
                :clearable="false"

            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group :label="msg('Image Uri')">
            <b-input v-model="router.imageUri"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="msg('Real estate')">
            <real-estate-picker v-model="router.realEstateId"/>
          </b-form-group>
        </b-col>
      </b-row>
      <!--files-->
      <b-row>
        <b-col cols="12">
          <b-card style="height: auto;" border-variant="warning">
            <div>
              <div class="card-header d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <feather-icon icon="FileIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Files').toUpperCase() }}
                  </h4>
                </div>
                <div>
                  <b-button size="sm" v-bind:variant="uploadFile ? 'warning' : 'outline-warning'"
                            @click="() => uploadFile = !uploadFile">
                    <i class="fas fa-upload"></i> {{ msg("Upload file") }}
                  </b-button>
                </div>
              </div>

              <div class="files-data">
                <div class="upload-backdrop" v-show="uploadFile === true">
                  <vue-dropzone id="contract-upload" class="h-100 darkcustom" :options="dropzoneOptions"
                                :useCustomSlot="true"
                                :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                                @vdropzone-success="uploadSuccess">
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                      <div class="subtitle">...or click to select a file from your computer</div>
                    </div>
                  </vue-dropzone>
                </div>
                <b-table-simple outlined>
                  <b-tr style="background-color: #c2aa8e!important;">
                    <b-th class="text-center" style="width: 40%;color: white">{{ msg('File') }}</b-th>
                    <b-th class="text-center" style="width: 20%;color: white">{{ msg('Note') }}</b-th>
                    <b-th class="text-center" style="width: 15%;color: white">{{ msg('Operations') }}</b-th>
                  </b-tr>
                </b-table-simple>
                <perfect-scrollbar class="position-relative" style="height: auto">
                  <b-table-simple outlined>
                    <b-tr v-for="(file) in (router.files || [])" v-bind:key="file.id"
                          style="white-space: nowrap; text-overflow: ellipsis" v-show="file.active">
                      <b-td style="width: 30%">
                        <i v-bind:class="file.filename" class="mx-1"
                           style="font-size: 30px; font-weight: bold"></i>{{ file.filename }} <span
                          v-if="file.size != null" style="color: red!important;">{{ getFileSize(file.size) }}</span>
                      </b-td>
                      <b-td class="text-center" style="width: 20%">
                        <b-form-textarea v-model="file.notes" @change="() => submitDocumentFile(index)"
                                         size="sm"></b-form-textarea>
                      </b-td>
                      <b-td class="text-center" style="width: 15%">
                        <b-link class="mr-2"><i class="icon fas fa-download w-auto"
                                                @click="() => onDownloadFile(file.id)"></i></b-link>
                        <b-link><i class="icon fas fa-eye w-auto" @click="() => onPreviewFile(file.id)"></i>
                        </b-link>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </perfect-scrollbar>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <!-- Save Button -->
      <div style="float: right">
        <b-button v-if="router.buid!=null" variant="outline-primary" class="btn-dialog" @click="update">
          {{ msg('Update') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import {msg} from "@babel/core/lib/config/validation/option-assertions";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: 'editRouterPanel',
  components: {
    RealEstatePicker,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      loaded: false,
      contractId: null,
      router: {},
      uploadFile: false,
      file: null,
      dropzoneOptions: {},

      clientId: null,
      contractList: [],
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('data', ['getAllBranches']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {
    let $this = this;

    this.loadRouter()
    this.loadDropZone();

    this.$root.$on('crm::submitRouter', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitRouter', this.listeners.submit)
  },
  methods: {
    msg,
    ...mapActions('router', ['getAllRouters', 'copySuccess', 'updateRouter', 'getRouterById']),
    ...mapActions('realEstateContracts', ['getAllByClients']),
    ...mapActions("document", ["downloadCustomFile"]),
    ...mapActions('router', ['updateDocumentFile']),

    loadDropZone() {
      let $this = this;

      this.dropzoneOptions = {

        url: window.config.apiUrl + 'router/upload/file/' + $this.id,
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx",

      }
    },
    uploadComplete(file, response) {
      let responseData = JSON.parse(file.xhr.responseText);
      let router = responseData.objects["Router"];
      this.router = router;
    },
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['Router'] == null)
          return;
        this.router = response.data['Router'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },

    submitDocumentFile(index) {
      this.updateDocumentFile({
        id: this.router.id,
        file: this.router.files[index]
      });
    },
    onDownloadFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {
        $this.asyncDownloadFile(file)
      })
    },
    onPreviewFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {

        let url = URL.createObjectURL(file.data);
        console.log("url is: ", url)
        window.open(url, '_blank')
      })
    },
    getFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
        return '';
      }
      const units = si
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10 ** dp;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
      return ' (' + bytes.toFixed(dp) + ' ' + units[u] + ')';
    },


    formatDate(date) {
      return date ? this.$moment(date, "YYYY-MM-DD").format("DD.MM.YYYY") : "No date";
    },
    loadRouter() {
      let $this = this
      this.getRouterById(this.id)
          .then(
              (router) => {
                $this.router = router
              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },

    update() {
      let $this = this

      this.updateRouter({router: this.router})
          .then(
              (id) => {

                $this.$emit('submitted', {status: true, server: id, id})

              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },

    validate(field) {
      return this.$v.router[field] != null && this.$v.router[field].$error === true
    }
    ,
    submit() {
      return this.createRouter()
    },

  },

}
</script>
<style scoped>

.dark-layout #realEstate-description {
  background-color: rgba(255, 255, 255, 0.66);
  color: #000000;
}

.quill-container #realEstate-description {

  min-height: 400px;
}

.dark-layout .darkcustom {
  background-color: #283046;
  color: #fff;
}
</style>


